<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon
        v-if="$store.state.AppActiveUser.userInfo.roles[0].permissions.map(permission => permission.name).includes('update_property_promotion_category')"
        icon="Edit3Icon" 
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" 
        @click="editRecord" 
      />
      <feather-icon 
        v-if="$store.state.AppActiveUser.userInfo.roles[0].permissions.map(permission => permission.name).includes('delete_property_promotion_category')"
        icon="Trash2Icon" 
        svgClasses="h-5 w-5 hover:text-danger cursor-pointer" 
        @click="confirmDeleteRecord" 
      />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',

  computed: {
    encryptedID () {
      return this.$secure.encrypt(this.params.data.id)
    }
  },

  methods: {
    editRecord () {
      this.$router.push({ name: 'property-promotion-category-edit', params: {id: this.encryptedID}})
    },
    
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${this.params.data.name}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("propertyPromotionCategoryManagement/destroyPropertyPromotionCategory", this.params.data.id)
                  .then(() => { this.showDeleteSuccess() })
                  .catch(err => { 
                    this.$vs.notify({
                      color: 'danger',
                      title: 'Failed to delete',
                      text: `The selected property promotion category can't be deleted`
                    })
                  })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Property Promotion Category Deleted',
        text: 'The selected property promotion category was successfully deleted'
      })
    }
  }
}
</script>
